<template>
  <v-container fluid>
    <CreateMatch @created="getMatches" />
    <UpdateMatch :matchData="selectedMatch" @updated="getMatches" />

    <v-row justify="center">
      <v-col cols="11" class="d-flex justify-start">
        <v-btn color="primary" fab @click="goToChampionship()">
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="11">
        <v-expand-transition>
          <loader v-if="loading" />
          <v-row v-else>
            <UpdateRound
              :data="round"
              @updated="getRound"
            />
            <RankingConsolidation
              :round="round"
              @started="startedConsolidation"
              @success="updateConsolidatedFlag"
            />
            <v-col cols="12">
              <v-card>
                <v-row justify="center">
                  <v-col
                    lg="3"
                    md="4"
                    sm="5"
                    cols="10"
                  >
                    <v-avatar
                      size="200"
                      color="primary"
                      class="ma-3 round-avatar"
                    >
                      <span
                        class="display-2 white--text text-center"
                      >
                        {{round.number}}°
                      </span>
                    </v-avatar>
                  </v-col>
                  <v-col
                    lg="9"
                    md="8"
                    sm="7"
                    cols="10"
                    class="mt-7"
                  >
                    <v-row>
                      <v-col
                        lg="8"
                        md="8"
                        sm="12"
                        cols="12"
                        class="d-flex flex-column align-start justify-start text-start"
                      >
                        <h2>
                          {{round.number}}° Rodada
                          <v-chip :color="round.open ? 'primary' : 'error'" class="ml-2">
                            {{round.open ? 'aberta' : 'fechada'}}
                          </v-chip>
                        </h2>
                        <h3 style="font-weight: normal">
                          De {{formatDate(round.startsAt)}} à {{formatDate(round.endsAt)}}<br>
                        </h3>
                      </v-col>
                      <v-col
                        lg="4"
                        md="4"
                        sm="12"
                        cols="12"
                        class="d-flex flex-column"
                        :class="['xs', 'sm'].includes(screenSize()) ? 'justify-start' : 'justify-center'"
                      >
                        <v-btn color="primary" @click="updateRound()" width="95%">
                          Editar
                        </v-btn><br>

                        <v-btn
                          v-if="!loading"
                          color="primary"
                          width="95%"
                          :disabled="round.consolidated || calculatingPoints"
                          @click="calculatePoints()"
                        >
                          <v-progress-circular
                            v-if="calculatingPoints"
                            indeterminate
                            size="25"
                            width="3"
                            class="px-6"
                            color="white"
                          />
                          <span>Calcular pontos</span>
                        </v-btn><br>

                        <v-btn
                          color="primary"
                          width="95%"
                          :disabled="round.consolidated || consolidatingPoints"
                          @click="consolidateRoundRanking()"
                        >
                          <v-progress-circular
                            v-if="consolidatingPoints"
                            indeterminate
                            size="25"
                            width="3"
                            class="px-6"
                            color="white"
                          />
                          Consolidar ranking
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card>
                <v-card-title class="d-flex justify-space-between">
                  <h3>Partidas</h3>
                  <v-btn color="primary" @click="createMatch()">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-row v-if="loadingMatches">
                    <v-col
                      v-for="i in 3"
                      :key="`matches-loader-${i}`"
                      lg="4"
                      md="4"
                      sm="4"
                      cols="12"
                    >
                      <v-skeleton-loader
                        class="mb-6"
                        elevation="1"
                        type="article"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-else justify-start class="mx-1 my-1">
                    <v-col
                      v-for="(match, index) in matches"
                      :key="match._id"
                      lg="4"
                      md="6"
                      sm="12"
                      cols="12"
                    >
                      <div class="match-card d-flex flex-column mouse-pointer py-3" @click="updateMatch(match)">
                        <div class="d-flex justify-center">
                          <div class="match-status-text" :style="`border: 1px solid ${getMatchStatusColor(match.status)}; color: ${getMatchStatusColor(match.status)}`">
                            {{ getMatchStatusText(match.status) }}
                          </div>
                        </div>
                        <div class="d-flex justify-space-around mr-3">
                          <div class="d-flex flex-column justify-center align-center">
                            <v-avatar
                              tile
                              left
                              size="50"
                              color="#F7F7F7"
                              class="ma-3"
                            >
                              <v-img
                                v-if="match.teamA.image"
                                :src="match.teamA.image"
                                contain
                                max-height="70%"
                                @error="teamImageErrorHandler(index, 'teamA')"
                              />

                              <span
                                v-else
                                class="display-2 white--text text-center"
                              >
                                <v-icon>
                                  mdi-soccer
                                </v-icon>
                              </span>
                            </v-avatar>
                            <span v-text="match.teamA.name" class="text-start mt-n3" style="font-weight: bold" />
                          </div>

                          <div class="d-flex justify-center align-center">
                            <span class="display-1 mr-2">
                              {{match.goalsA}}
                            </span>
                            <v-icon color="#979797">
                              mdi-close
                            </v-icon>
                            <span class="display-1 ml-2">
                              {{match.goalsB}}
                            </span>
                          </div>

                          <div class="d-flex flex-column justify-center align-center">
                            <v-avatar
                              tile
                              left
                              size="50"
                              color="#F7F7F7"
                              class="ma-3"
                            >
                              <v-img
                                v-if="match.teamB.image"
                                :src="match.teamB.image"
                                contain
                                max-height="70%"
                                @error="teamImageErrorHandler(index, 'teamB')"
                              />

                              <span
                                v-else
                                class="display-2 white--text text-center"
                              >
                                <v-icon>
                                  mdi-soccer
                                </v-icon>
                              </span>
                            </v-avatar>
                            <span v-text="match.teamB.name" class="text-start mt-n3" style="font-weight: bold" />
                          </div>
                        </div>
                      </div>
                    </v-col>

                    <v-col v-if="totalPages > page" cols="12" class="d-flex justify-center">
                      <v-btn text @click="changeMatchesPage()">
                        <v-icon>mdi-chevron-down</v-icon>
                        Ver mais
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-expand-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const imageOffIcon = require('@/assets/icons/image-off.png')

export default {
  components: {
    Loader: () => import('@/components/LoadingDetails'),
    UpdateRound: () => import('./UpdateRound.vue'),
    RankingConsolidation: () => import('./RankingConsolidation.vue'),
    CreateMatch: () => import('@/views/matches/components/CreateMatch.vue'),
    UpdateMatch: () => import('@/views/matches/components/UpdateMatch.vue')
  },
  computed: {
    updateRoundModal: {
      get () {
        return this.$store.state.updateRoundModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'updateRoundModal',
          value: val
        })
      }
    },
    createMatchModal: {
      get () {
        return this.$store.state.createMatchModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'createMatchModal',
          value: val
        })
      }
    },
    updateMatchModal: {
      get () {
        return this.$store.state.updateMatchModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'updateMatchModal',
          value: val
        })
      }
    },
    confirmRoundRankingConsolidationModal: {
      get () {
        return this.$store.state.confirmRoundRankingConsolidationModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'confirmRoundRankingConsolidationModal',
          value: val
        })
      }
    }
  },
  data () {
    return {
      loading: true,
      loadingMatches: true,
      calculatingPoints: false,
      consolidatingPoints: false,
      id: null,
      round: null,
      matches: [],
      selectedMatch: {},
      page: 1,
      pageSize: 4,
      totalPages: 0
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.getRound()
    this.getMatches()
  },
  methods: {
    getRound () {
      if (!this.id) return
      this.loading = true
      const championshipId = this.$store.state.selectedChampionship
      this.$http.get(`championships/${championshipId}/rounds/${this.id}`)
        .then(res => {
          const { data } = res.data
          this.calculatingPoints = data.calculatingPoints
          this.consolidatingPoints = data.consolidatingPoints
          this.round = data
        })
        .catch(err => {
          this.$toast.error(err.response.data.message)
        })
        .finally(() => (this.loading = false))
    },
    calculatePoints () {
      this.calculatingPoints = true
      const championshipId = this.$store.state.selectedChampionship
      const roundId = this.id

      this.$http.post('ranking/calculate-points', null, {
        params: {
          championship: championshipId,
          round: roundId
        }
      })
        .then(res => {
          this.$toast.success('Os pontos da rodada começaram a ser calculados. Espere alguns minutos.')
          this.calculatingPoints = false
        })
        .catch(err => {
          this.$toast.error(err.response.data.message)
        })
        .finally(() => (this.calculatingPoints = false))
    },
    getMatches () {
      if (!this.id) return
      this.loadingMatches = true
      const championshipId = this.$store.state.selectedChampionship
      this.$http.get(`championships/${championshipId}/rounds/${this.id}/matches?page=${this.page}&pageSize=${this.pageSize}`)
        .then(res => {
          const { data, totalPages } = res.data
          this.matches = this.getUniqueListBy([...this.matches, ...data], '_id')
          this.totalPages = totalPages
        })
        .catch(err => {
          this.$toast.error(err.response.data.message)
        })
        .finally(() => (this.loadingMatches = false))
    },
    updateRound () {
      this.updateRoundModal = true
    },
    changeMatchesPage () {
      this.page++
      this.getMatches()
    },
    createMatch () {
      this.$store.commit('SET_SELECTED_ROUND', this.id)
      this.createMatchModal = true
    },
    updateMatch (match) {
      this.$store.commit('SET_SELECTED_ROUND', this.id)
      this.selectedMatch = match
      this.updateMatchModal = true
    },
    updateConsolidatedFlag () {
      this.consolidatingPoints = false
      this.round.consolidated = true
    },
    teamImageErrorHandler (index, attr) {
      this.matches[index][attr].image = imageOffIcon
    },
    goToChampionship () {
      this.$router.push(`/campeonatos/editar/${this.$store.state.selectedChampionship}`)
    },
    startedConsolidation () {
      this.consolidatingPoints = true
    },
    consolidateRoundRanking () {
      this.confirmRoundRankingConsolidationModal = true
    },
    getMatchStatusText (status) {
      const statuses = {
        waiting: 'Esperando início',
        started: 'Partida iniciada',
        finished: 'Partida finalizada',
        canceled: 'Partida cancelada'
      }

      return statuses[status]
    },
    getMatchStatusColor (status) {
      const statuses = {
        waiting: '#acacac',
        started: '#78aa00',
        finished: '#ff5252 ',
        canceled: '#ff5252'
      }

      return statuses[status]
    }
  }
}
</script>

<style scoped>
  .match-card {
    border: 1px solid #BDBDBD !important;
    border-radius: 3px !important;
  }

  .team-image {
    border: 1px solid #BDBDBD !important;
    border-radius: 3px !important;
  }

  .round-avatar {
    border-radius: 3px !important;
  }

  .match-status-text {
    width: 50%;
    border-radius: 3px;
  }
</style>
